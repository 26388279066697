import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../Footer';



const Cancel = ({transactionDetail, serviceName}) => {
    
    const {notif_token} = useParams();
    const navigate = useNavigate()

    function returnToApp(){
        window.location.href= transactionDetail.customer_return_url
        
    }

    function goToSupport(){
        navigate(`/support/${notif_token}`)
    }
  return (
    <>

          <div className="grid grid-cols-1 md:grid-cols-1 ">             

            <div className="w-full max-w-md mx-auto">

                <div className="flex flex-col items-center justify-center h-screen ">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-16 h-16 mb-4 text-red-500">
                          <circle cx="12" cy="12" r="10" />
                          <line x1="7" y1="7" x2="17" y2="17" />
                          <line x1="7" y1="17" x2="17" y2="7" />
                      </svg>

                    <h1 className="mb-2 text-3xl font-semibold text-center text-gray-800">Paiement échoué!</h1>                       
                    <p className="text-sm text-center text-gray-500 ">Votre paiement de  
                        <span className="text-2xl">
                          {Number( transactionDetail.montant  ).toLocaleString()}  {transactionDetail.currency}
                        </span>
                        a échoué 
                    </p>
                    <span className='mb-8 ml-1 font-bold text-center text-gray-900'>
                        { serviceName }
                    </span>
                    <div className='flex justify-between'>
                        <button onClick={returnToApp}  className="px-4 py-2 mr-2 text-white bg-green-500 rounded-lg hover:bg-green-600">Retour</button>
                        <button onClick={goToSupport} className="px-4 py-2 ml-2 text-white bg-red-500 rounded-lg hover:bg-red-600">Contacter Support</button>

                    </div>
                    
                    
                    <Footer transactionDetail={transactionDetail}/>
                </div>

            </div>

          </div>
    </>
  )
}

export default Cancel