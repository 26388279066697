import React from 'react'
// import { Input, Typography, Button } from '@material-tailwind/react';
// import { LockClosedIcon } from '@heroicons/react/24/solid';
// import { useForm } from 'react-hook-form';
// import { Link } from 'react-router-dom';
import Footer from './Footer';

const WaitForConfirmation = ({ 
    transactionDetail, 
    serviceName, 
    nomOperateur, 
    messageConfirmation, 
    codeUssdConfirmation,
    methode
}) => {

    // const [codeValidation, setCodeValidation] = useState()
    // const { register, handleSubmit, formState: { errors } } = useForm();

    // function cancelTransaction() {
    //     // props.showWindowsValidation(event)
    // }

    // function validation(data) {
    //     // event.preventDefault();        
    //     console.log(data);
    // }


    return (
        <>
            <div className="grid grid-cols-1 m-3 md:grid-cols-1">

                <div className="relative block">
                    <h4 className="items-baseline flex-auto gap-1 mt-3 font-bold text-center ">
                        <span className="text-4xl">
                            {Number(transactionDetail.montant).toLocaleString()}  {transactionDetail.currency}
                        </span>
                    </h4>

                    <div className="flex justify-center mt-3">
                        <span className='ml-1 font-bold text-center text-gray-900 '>
                            {serviceName}
                        </span>
                    </div>
                    <p className="mb-2 text-sm font-normal text-center text-gray-500 ">{transactionDetail.description}</p>
                </div>


                <div className="w-full max-w-md mx-auto">

                    <div className="flex flex-col items-center justify-center mb-9">
                        {methode === "mtn_ci" && 
                            <span className="w-20 h-20 bg-center bg-no-repeat bg-cover " style={{ backgroundImage: "url('./../../mtn.webp')" }}></span>
                        }
                        {methode === "moov_ci" && 
                            <span className="w-20 h-20 bg-center bg-no-repeat bg-cover " style={{ backgroundImage: "url('./../../moov.webp')" }}></span>
                        }                         
                        <p className="mt-1 font-bold text-center text-gray-900">{nomOperateur}</p>
                        <p className="mt-0 text-sm text-center text-gray-500">{messageConfirmation}</p>
                        <div className="w-12 h-12 border-t-2 border-b-2 border-blue-700 rounded-full animate-spin"></div>
                        <p className="mt-2 text-sm text-center text-gray-600">{codeUssdConfirmation}</p>
                        
                    </div>

                    {/* <form onSubmit={handleSubmit(validation)}>

                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-4 font-medium"
                        >
                            Code de validation
                        </Typography>

                        <Input
                            label="Code"
                            type='number'
                            autoFocus={true}
                            maxLength={7}
                            value={codeValidation}
                            onChange={(event) => setCodeValidation(event.target.value)}
                            icon={
                                <LockClosedIcon className="w-5 h-5 text-blue-gray-300" />
                            }

                            className={`${errors.codeValidation ? 'border-red-500' : ''}`}


                            {...register('codeValidation',
                                {
                                    required: true,
                                    pattern: /\d+/,
                                    minLength: 4
                                }
                            )}
                        />
                        {errors.codeValidation &&

                            <span className=''>
                                <Typography variant="small" color="red">
                                    Code obligatoire
                                </Typography>
                            </span>
                        }

                        <div className='flex justify-center'>
                            <Button
                                type='submit'
                                color="green"
                                className='mt-3 mr-2'
                            >
                                Confirmer code
                            </Button>

                            <Button
                                color="red"
                                className='mt-3'
                                onClick={cancelTransaction}
                            >
                                Annuler
                            </Button>
                        </div>


                    </form> */}
                    <Footer transactionDetail={transactionDetail}/>

                </div>

            </div>


        </>
    )
}

export default WaitForConfirmation