import React from 'react'
import { Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const Footer = ({transactionDetail} ) => {
  return (
    <>
          <div className='flex justify-between mt-7'>
              <Link to={transactionDetail.customer_return_url} className='flex text-sm text-green-600 mr-7'>Retour</Link>
              <Typography
                  variant="small"
                  color="white"
                  className="flex items-center justify-center font-normal text-gray-600 opacity-80"
              >
                  <LockClosedIcon className="-mt-0.5 h-4 w-4" /> Secure By

                  <span className='ml-1 font-bold text-blue-700'>
                      HTIC
                  </span>
                  <span className='font-bold text-green-700'>
                      PAY
                  </span>
              </Typography>
          </div>   
    </>
  )
}

export default Footer