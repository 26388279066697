import React from 'react'

const Loader = () => {
  return (
    <>        
      <div>            
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-gray-300 bg-opacity-90 flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-700"></div>
        </div>              
      </div> 
    </>
  )
}

export default Loader