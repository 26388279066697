import React, { useState } from 'react'
import {
  Typography,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import api from '../api/api';
import { useParams,} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import fr from 'react-phone-input-2/lang/fr.json'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import 'react-phone-input-2/lib/material.css'

const FlutterwaveCardPrepaid = ({details}) => {

  const [phone, setPhone] = useState("");
  // const [errorPhone, setErrorPhone] = useState();

  const schema = Yup.object().shape({   
    holderName: Yup.string().required('Le nom du titulaire de la carte est requis'),
    email: Yup.string().email('Format d\'email invalide').required('L\'email est requis'),
  });

  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const {notif_token} = useParams()
  const [transactionOrderId, setTransactionOrderId] = useState(generateUniqueID());
  // const [emailFlutterWave, setEmailFlutterWave] = useState("");
  // const [telFlutterWave, setTelFlutterWave] = useState("");
  // const [ isLoading, setIsLoading ] = useState(false);

  
  const config = {
    public_key: 'FLWPUBK-81ad3db26c3e3a4af70002f6d15cd24d-X',
    tx_ref: transactionOrderId,
    amount: details.montant,
    currency: details.currency,
    payment_options: 'card,mobilemoneyfranco,mobilemoneyghana,mobilemoneyuganda,mobilemoneyrwanda,mobilemoneyzambia',
    customer: {
      email: watch("email"), //'goncoolio@gmail.com',
      phone_number:  phone, //'2250757060000',
      name: watch("holderName") , //'Coulibaly Ousmane',
    },
    customizations: {
      title: details.service_name, //details.service_name, //'Miss Tabouret Royal',
      description: 'Payement HTICPAY', //details.description, //'Concours miss tabouret royal',
      logo: 'https://app.hticpay.com/hticpay/img/favicons/htic_icone.png',
    },
  };
  

  const handleFlutterPayment = useFlutterwave(config);
  
  const handleSubmitData = async (data) => {  
    try {
      // submitToHticPayForGenereeOrderID();
      // setTransactionOrderId(order_id);

      // const oooo = generateUniqueID();
      console.log('Ousmane', transactionOrderId);

      if (transactionOrderId) {

        handleFlutterPayment({
          callback: (response) => {
            
            
            try {
              

              const { status, tx_ref, flw_ref, customer, transaction_id} = response;
              const {name, email, phone_number} = customer;

              api.post(`/api/checkout/v1/cc/checkoutCreditCardPost/${notif_token}`, JSON.stringify({
                "full_name": name,
                "email": email,
                "mobile": phone_number,
                "payment_method": 'credit_card',
                "order_id": tx_ref,        
                "status": status,
                "flw_ref": flw_ref,
                "transaction_id": transaction_id,
              }));        
        
              // console.log('res', res)

              // const { data } = res;
              // const { transaction } = data;

              

              // if (transaction) {
              //   closePaymentModal(); 
              //   // window.location.pathname = `/checkout/${notif_token}`;
              // }
              //  window.location.pathname = `/checkout/${notif_token}`;
              // console.log('transactions', res.data.transaction);

              
              // setIsLoading(false);

            } catch (error) {
              console.log(error);
              // setIsLoading(false);
              // Gérer les erreurs de la requête ici si nécessaire
              throw error; // Réémettre l'erreur pour que la fonction appelante puisse également la gérer
            }

            // setTimeout(() => {
            //   closePaymentModal();
            // }, 7000)
            
          },
          onClose: () => {
            // submitToHticPayForGenereeOrderID()
            window.location.pathname = `/checkout/${notif_token}`;
          },
        });
      }
    } catch (error) {
      console.log(error);
      // Gérer les erreurs de la requête ici si nécessaire
    }

  };


  function generateUniqueID() {
    const timestamp = new Date().toISOString();
    const randomNumber = Math.floor(Math.random() * (9000 - 1010 + 1)) + 1010;
    const uniqueID = `${timestamp}${randomNumber}`;
    const cleanedString = uniqueID.replace(/[\D]/g, '');
    return cleanedString;
  } 


  // const submitToHticPayForGenereeOrderID = async (flutter) => {

  //   try {
  //     const response = await api.get(`/api/checkout/v1/checkout/${notif_token}`);


  //     console.log('transactions', response.data.transaction);
  //     const { order_id } = response.data.transaction;
  //     // setTransactionOrderId(order_id);

  //     console.log(order_id);
  //     // setIsLoading(false);
  //     return order_id;
  //   } catch (error) {
  //     console.log(error);
  //     // setIsLoading(false);
  //     // Gérer les erreurs de la requête ici si nécessaire
  //     throw error; // Réémettre l'erreur pour que la fonction appelante puisse également la gérer
  //   }

  //   // api.post(`/api/checkout/v1/checkoutPost/${notif_token}`, JSON.stringify({
  //   //   "mobile": phone,
  //   //   "payment_method": 'credit_card',
  //   //   "full_name" : watch("holderName")
  //   // }))
  //   // .then((response) => {
  //   //   console.log('transactions', response.data.transaction)
  //   //   const { order_id } = response.data.transaction;

  //   //   // setTransactionOrderId(order_id)

  //   //   console.log(order_id)
  //   //   // window.location.href=response.data
  //   //   // routeByMethod(methode, response.data)
  //   //   console.log(response);
  //   //   setIsLoading(false);
  //   //   return order_id;
  //   // })
  //   // .catch((error) => {
  //   //   console.log(error)
  //   //   // navigate("/")
  //   //   setIsLoading(false);
  //   // })
  // }

//   {
//     "order_id": "28072023120715491624",
//     "montant": 5,
//     "statut": "PENDING",
//     "currency": "USD",
//     "customer_return_url": "https://paypal.com",
//     "description": "Paiement de la participation au projet PJ20221903",
//     "moyen_paiement_id": 5,
//     "moyen_paiement": "CREDIT CARD",
//     "code_moyen_paiement": "credit_card",
//     "user_service_id": "864a40d3-f989-4c93-9c0f-31993f1e8ee5",
//     "service_name": "Muslim CrowdFunding"
// }


  // Faire une requette à mon serveur pour initier le paiement generer un order_id et utiliser la reponse por soummettre la demande

//   {
//     "status": "successful",
//     "customer": {
//         "name": "Ousmane COULIBALY",
//         "email": "aide@muscrow.com"
//     },
//     "transaction_id": 4490556,
//     "tx_ref": 1690521697217,
//     "flw_ref": "FLW-MOCK-a7510bc03aefb7167e207dc4d9f0a8d0",
//     "currency": "USD",
//     "amount": 5,
//     "charged_amount": 5,
//     "charge_response_code": "00",
//     "charge_response_message": "Approved. Successful",
//     "created_at": "2023-07-28T05:22:23.000Z"
// }


  return (
    <>

      <form onSubmit={handleSubmit(handleSubmitData)} className="mt-1 flex flex-col gap-4">
       
        <div className="">
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-4 font-medium"
          >
            
          </Typography>
          
          <div className="flex justify-center mt-1 mb-0">
              <div className='flex justify-center'>
                <PhoneInput
                    country={"ci"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    localization={fr}
                    masks={{ci: '.. .. .. ....', }}
                    countryCodeEditable={false}
                    inputProps={{
                      name: 'Mobile',
                      required: true,
                      autoFocus: true,
                    }}                    
                />
              </div>
          </div>

          {/* {new Date().toISOString().slice(0, 10)}
          {Math.floor(Math.random() * 8000) + 1010} */}
          <div className=' mb-3'>
            <label className="font-semibold">
              {"Email"}
            </label>
            <input
            {...register('email')}
              className={`${errors.email ? "border-3 border-red-500 focus:border-red-500" : "border-blue-500 focus:border-blue-600"} border w-full mt-0 px-3 py-2 text-gray-800 bg-transparent outline-none shadow-sm rounded-lg`}
            />
            {errors.email &&
              <span className=''>
                <Typography variant="small" color="red">
                  {errors.email.message}
                </Typography>
              </span>
            }

          </div>  

          <div className=' mb-3'>
            <label className="font-semibold">
              {"Nom et Prénom"}
            </label>
            <input
              {...register('holderName')}
              className={`${errors.holderName ? "border-3 border-red-500 focus:border-red-500" : "border-blue-500 focus:border-blue-600"} border w-full mt-0 px-3 py-2 text-gray-800 bg-transparent outline-none shadow-sm rounded-lg`}
            />
            {errors.holderName &&
              <span className=''>
                <Typography variant="small" color="red">
                  {errors.holderName.message}
                </Typography>
              </span>
            }

          </div>    
          
        </div>      


        <button  className='flex justify-center p-3 mt-2 mb-2 ml-1 font-bold text-white  rounded hover:bg-green-700 bg-blue-500'
            disabled={errors.email || errors.holderName}        
        >
          PAY {Number( details.montant  ).toLocaleString()}  {details.currency}
        </button>
    
      </form>

    </>
  )
}

export default FlutterwaveCardPrepaid