import { useState } from 'react'
import { Tab } from '@headlessui/react'
import CardPrepaid from './CardPrepaid';
import Wallets from './Wallets';
import Footer from './Footer';
import WaitForConfirmation from './WaitForConfirmation';
import FlutterwaveCardPrepaid from './FlutterwaveCardPrepaid';

const Index = ({transactionDetail, serviceName}) => {

    
    const [showWaitForConfirmation, setShowWaitForConfirmation] = useState(false);
    const [nomOperateur, setnomOperateur] = useState()
    const [messageConfirmation, setMessageConfirmation] = useState();
    const [codeUssdConfirmation, setCodeUssdConfirmation] = useState();
    // const [logoMedia, setLogoMedia] = useState();
    const [methode, setMethode] = useState();
    
  let [categories] = useState({
    "Mobile Money - Wallets": [],
    "Credit Card - Other Money": [],
  }) 
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handlerWaitForConfirmation = (nom_operateur, message_confirmation, code_ussd_confirmation, methode) => {
    setnomOperateur(nom_operateur);
    setMessageConfirmation(message_confirmation);
    setCodeUssdConfirmation(code_ussd_confirmation);
    setMethode(methode)
    setShowWaitForConfirmation(true);

  }

//   setService(transactionDetail.user_service);
// Moov Money
// Nous avons juste bésoin de votre confirmation
// Composez *155# puis OK sur votre téléphone mobile
  return (
    <>
        { showWaitForConfirmation &&
            <WaitForConfirmation 
                transactionDetail={transactionDetail} 
                serviceName={serviceName}
                nomOperateur={nomOperateur}
                messageConfirmation={messageConfirmation}
                codeUssdConfirmation={codeUssdConfirmation}
                methode={methode}

            />
        }


        { !showWaitForConfirmation && 
            <div className="grid grid-cols-1 m-3 md:grid-cols-2">

                <div className="relative block">
                    <h4 className="items-baseline flex-auto gap-1 mt-3 font-bold text-center ">
                        <span className="text-4xl">
                            {Number( transactionDetail.montant  ).toLocaleString()}  {transactionDetail.currency}
                        </span>
                    </h4>
                    
                    <div className="flex justify-center mt-3">
                        <span className='ml-1 font-bold text-center text-gray-900 '>                            
                            { serviceName }
                        </span>
                    </div>
                    <p className="mb-8 text-sm font-normal text-center text-gray-500 ">{ transactionDetail.description }</p>
                </div>


                <div className="w-full max-w-md mx-auto">
                    <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 bg-green-400 rounded-xl">
                            {Object.keys(categories).map((category) => (
                                <Tab
                                    key={category}
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-800 focus:outline-none focus:ring-2',
                                            selected
                                                ? 'bg-white shadow'
                                                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                        )
                                    }
                                >
                                    {category}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels className="mt-2">
                            <Tab.Panel

                                className={classNames(
                                    'rounded-xl bg-gray-50 p-3',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-700 focus:outline-none focus:ring-2'
                                )}
                            >
                                {/* Wallets */}
                                <Wallets showWaitComponent={handlerWaitForConfirmation}/>
                                {/* End Wallets */}

                            </Tab.Panel>
                            <Tab.Panel

                                className={classNames(
                                    'rounded-xl bg-gray-100 p-3',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                )}
                            >
                                {/* Card Visa */}
                                {/* <CardPrepaid /> */}
                                <FlutterwaveCardPrepaid details={transactionDetail} />
                                {/* Card Visa */}
                            </Tab.Panel>

                        </Tab.Panels>
                    </Tab.Group>

                    <Footer transactionDetail={transactionDetail} />

                </div>

            </div>
        }
    </>
  )
}

export default Index