import React from 'react'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {  Route, Routes } from 'react-router-dom';
import RootComponent from './components/RootComponent';
import NotFound from './components/status/NotFound';
import Support from './components/Support';

NProgress.configure({ showSpinner: false });

export default function App() {
 
  window.addEventListener('beforeunload', () => {
    NProgress.start();
  });
  
  return (
    
    <>  
      <Routes>
        {/* <Route exact path='/' element={<RootComponent/>} /> */}
        <Route exact path='/checkout/:notif_token' element={<RootComponent/>} />
        <Route exact path='/support/:notif_token' element={<Support/>} />
        <Route exact path='/*' element={<NotFound/>} />
      </Routes>  
              
    </>
  )
}

window.addEventListener('load', () => {
  NProgress.done();
});





