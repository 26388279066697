import React, { useEffect, useState } from 'react'
import Index from './Index';
import Success from './status/Success';
import Cancel from './status/Cancel';
import api from '../api/api';
import Loader from './Loader';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr'; 



const RootComponent = () => { 

  const {notif_token} = useParams();
  const fetcher = (url) => api.get(url).then((res) => res.data)
  const { data: transaction, error, isLoading } = useSWR(`/api/checkout/v1/${notif_token}`, fetcher, { refreshInterval: 0, revalidateOnFocusLost: true, });
  const [transactionDetail, setTransactionDetail] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const navigate = useNavigate();



  
  useEffect(() => {
   if (transaction) {
    setTransactionDetail(transaction.transaction)
    setServiceName(transaction.transaction.service_name)
    // console.log(transaction)

    // Si la moyen de paiement de la transaction est defini
    // alors faire un chargement jusqu'a ce que le statut change 
    
  
   }
  }, [transaction]);
  

  useEffect(() => {
    if (error) {
      console.log(error)
      // navigate("/")
    }
  }, [error, navigate]);

  

  return (
    <>
      <div>
        {isLoading && (
          <Loader />
        )}
        
      </div>

        <div className="flex flex-col items-center justify-center min-h-screen ">
        <div className="w-full px-2">          

          
          {transactionDetail.statut === "PENDING" &&
            <div>
              {transactionDetail.moyen_paiement_id !== null  ? (
                <Loader />
              ) : (

                // Mettre une condition qui dit que si le type de moyen
                //  est choisie afficher un message consequent
                //  avec une question confirmez  ou annuler le paiement 
                // sinon afficher ceci (en dessous)
                <Index transactionDetail = {transactionDetail} serviceName = {serviceName} />              
              )
              }
            </div>          
          }

          
          {transactionDetail.statut === "SUCCESS" &&
            <div>              
              <Success transactionDetail = {transactionDetail} serviceName = {serviceName}/>
            </div>
          }

          {transactionDetail.statut === "FAILED" &&
            <div>              
                <Cancel transactionDetail = {transactionDetail} serviceName = {serviceName}/>
            </div>
          }

          {transactionDetail.statut === "CANCELLED" &&
            <div>              
                <Cancel transactionDetail = {transactionDetail} serviceName = {serviceName}/>
            </div>
          }

        </div>
      </div>
    </>
  )
}

export default RootComponent