import React from 'react'
import { Link } from 'react-router-dom';
import { Typography, Button, Input, Textarea } from '@material-tailwind/react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const Support = () => {
  return (
    <>
          <div className="m-7">

              <div className="w-full max-w-md mx-auto">
                  <form className="mt-1 mb-3 flex flex-col gap-4">

                      <div className="my-6">
                          <Typography
                              variant="h3"
                              color="blue-gray"
                              className="mb-4 font-medium"
                          >
                              Reclamation client
                          </Typography>



                          <Input label="Nom et Prénoms"
                              className='mb-2'
                          //    value={holderName}
                          //    onChange={(event) => setHolderName(event.target.value)}
                          />

                          <div className="flex items-center gap-4 mt-5 mb-5">
                              <Input
                                  label="Expires"
                                  maxLength={5}
                                  //   value={formatExpires(cardExpires)}
                                  //   onChange={(event) => setCardExpires(event.target.value)}
                                  containerProps={{ className: "min-w-[72px]" }}

                              />

                              <Input
                                  label="CVC"
                                  maxLength={4}
                                  //   value={cvc}
                                  //   onChange={(event) => setCvc(event.target.value)}
                                  containerProps={{ className: "min-w-[52px]" }}

                              />

                          </div>

                          <Input label="Email"
                              type='email'
                          //    value={holderName}
                          //    onChange={(event) => setHolderName(event.target.value)}
                          />
                          {/* <div className=' mb-5 mt-0'>
                            {errorHolderName &&
                                <span className=''>
                                <Typography variant="small" color="red">
                                    {errorHolderName}
                                </Typography>
                                </span>
                            }
                            </div> */}
                          <div className='mt-4'>

                              <Textarea
                                  label='Message'
                              />
                          </div>

                      </div>
                      <Button size="lg" type='submit'>Soumettre la reclamation</Button>

                  </form>


                  <div className='flex justify-between'>
                      <Link to="http://hticsms.com" className='text-green-600 flex text-sm'>Retour</Link>
                      <Typography
                          variant="small"
                          color="gray"
                          className="flex items-center justify-center font-normal opacity-80"
                      >
                          <LockClosedIcon className="-mt-0.5 h-4 w-4" /> Payments are secure By

                          <span className='text-blue-700 font-bold ml-1'>
                              HTIC
                          </span>
                          <span className='text-green-700 font-bold'>
                              PAY
                          </span>
                      </Typography>
                  </div>

              </div>

          </div>
    </>
  )
}

export default Support