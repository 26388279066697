import axios from "axios";
// import dotenv from "dotenv";
// require('dotenv').config()
// dotenv.config();

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'X_AUTH_TOKEN': process.env.REACT_APP_X_AUTH_TOKEN,
        "Content-Type": "application/json"
    },
    maxBodyLength: Infinity,
    withCredentials: true,
});


export default api;