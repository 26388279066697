import { Fragment, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import fr from 'react-phone-input-2/lang/fr.json'
// import 'react-phone-input-2/lib/style.css'
// import "react-phone-input-2/lib/bootstrap.css";

import 'react-phone-input-2/lib/material.css'
// import WaitForConfirmation from './WaitForConfirmation';
import { Typography } from '@material-tailwind/react';
import api from '../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from './Loader';


const Wallets = (props) => {

  
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [methode, setMethode] = useState(false);
  const navigate = useNavigate();
  const {notif_token} = useParams();
  const {showWaitComponent} = props;

  

  function validationMobileNumber(phone, methode){
    if (phone.trim() === '' || phone.length < 13) {
      return setErrorPhone('Numéro téléphone invalide.');
    } else {
      setErrorPhone('');
      setIsLoading(true);
      handlePaymentMethod(phone, methode);
      console.log(phone);
      console.log(methode);
    }

  }

  function routeByMethod(methode, response){
    if (methode === "wave_ci") {
      window.location.href=response
    }

    if (methode === "orange_ci") {
      console.log("orange_ci")
    }

    if (methode === "mtn_ci") {

      // Si il y a une reponse 400 || 404 || 500 Affiché une fenetre d'erreur       
      if (response.res === null) {
        showWaitComponent(
          response.mtn_nom_operateur, 
          response.mtn_message_confirmation, 
          response.mtn_code_ussd_confirmation,
          methode
        )
      } else {
        // Alert une erreur afficher l'erreur
      }
      // response.

    }

    if (methode === "moov_ci") {
      console.log("moov_ci")
    }
  }

  function handlePaymentMethod(phone, methode) {

    
    api.post(`/api/checkout/v1/checkoutPost/${notif_token}`, JSON.stringify({
        "mobile": phone,
        "payment_method": methode
    }))
      .then((response) => {
        // console.log(response.data)
        // window.location.href=response.data
        routeByMethod(methode, response.data)
        setIsLoading(false);   
      })
      .catch((error) => {
        console.log(error)
        navigate("/")
        setIsLoading(false);
      })
   
  }

  
  

  return (
    <>  
        <div>
          {isLoading && (
            <Loader />
          )}
        </div>
    
        <div>
          <div className="flex justify-center mt-1 mb-0">

              <div className='flex justify-center'>
                <PhoneInput
                    country={"ci"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    onlyCountries={['ci']}
                    localization={fr}
                    masks={{ci: '.. .. .. ....', }}
                  //   disableCountryCode
                    countryCodeEditable={false}
                    inputProps={{
                      name: 'Mobile',
                      required: true,
                      autoFocus: true,
                    }}                    
                />
              </div>
          </div>
              <div className='flex justify-center mb-5'>
                  {errorPhone && 
                    <span className=''>
                    <Typography variant="small" color="red">
                      {errorPhone}
                    </Typography>
                </span>
                  }

              </div>
          
          <div className='grid grid-cols-1 md:grid-cols-2'>         

          
            <button 
                onClick={() => validationMobileNumber(phone, "wave_ci")}
                className="flex justify-center p-3 mt-2 mb-2 ml-1 font-bold text-white bg-blue-500 rounded hover:bg-blue-700">
                <div className='flex'>
                    <span className="w-10 h-10 bg-center bg-no-repeat bg-cover " style={{ backgroundImage: "url('./../../wave.webp')" }}></span>
                    <span className='p-2 text-sm'>Payer avec Wave</span>
                </div>
            </button>        
      
          
            {/* <button 
              // onClick={() => validationMobileNumber(phone, "orange_ci")}
              disabled
              className={`flex justify-center p-3 mt-2 mb-2 ml-1 font-bold text-white bg-gray-500 rounded ${!true && "hover:bg-orange-700"} `}>
              <div className='flex'>
                <span className="w-10 h-10 bg-center bg-no-repeat bg-cover " style={{ backgroundImage: "url('./../../orange.webp')" }}></span>
                <span className='p-2 text-sm'>Payer avec Orange</span>
              </div>
            </button> */}
          
          
            
            
            <button 
              onClick={() => validationMobileNumber(phone, "mtn_ci")}
              className="flex justify-center p-3 mt-2 mb-2 ml-1 font-bold text-white bg-yellow-700 rounded hover:bg-yellow-800">
              <div className='flex'>
                  <span className="w-10 h-10 bg-center bg-no-repeat bg-cover " style={{ backgroundImage: "url('./../../mtn.webp')" }}></span>
                  <span className='p-2 text-sm'>Payer avec MTN</span> 
              </div>
            </button>
            
          
            {/* <button 
              // onClick={() => validationMobileNumber(phone, "moov_ci")}
              disabled
              className={`flex justify-center p-3 mt-2 mb-2 ml-1 font-bold text-white rounded bg-gray-500 ${!true && "hover:bg-blue-800"}`}>
              <div className='flex'>
                  <span className="w-10 h-10 bg-center bg-no-repeat bg-cover " style={{ backgroundImage: "url('./../../moov.webp')" }}></span>
                  <span className='p-2 text-sm'>Payer avec Moov</span> 
              </div>
            </button> */}

          </div>
        </div>
     
    </>
  )
}

export default Wallets