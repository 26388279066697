import React from 'react'
import { Typography } from '@material-tailwind/react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const NotFound = () => {
  return (
    <>
      
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
        <div className="text-red-500 text-9xl">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.2} stroke="currentColor" className="w-20 h-20">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
          </svg>

        </div>

        <h1 className="text-3xl font-bold text-center text-gray-800 mb-2">404: Page non trouvée</h1>
        <p className="text-gray-700 mb-8">La page que vous recherchez n'existe pas.</p>

        <Typography
          variant="small"
          color="gray"
          className="bg-gray-100 mt-6 flex items-center justify-center font-normal opacity-80"
        >
          <LockClosedIcon className=" h-4 w-4" /> Payments are secure By
          <span className='text-blue-700 font-bold ml-1'>
            HTIC
          </span>
          <span className='text-green-700 font-bold'>
            PAY
          </span>
        </Typography>
      </div>
    </>
  )
}

export default NotFound