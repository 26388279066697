import React from 'react'
import Footer from '../Footer';

const Success = ({transactionDetail, serviceName}) => {

    function returnToSite(){
        window.location.href=transactionDetail.customer_return_url
    }
  return (
    <>

          <div className="grid grid-cols-1 md:grid-cols-1">              

                <div className="w-full max-w-md mx-auto">

                    <div className="flex flex-col items-center justify-center h-screen ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80">
                            <circle cx="50" cy="50" r="45" fill="#8BC34A" />
                            <path fill="#fff" d="M43.2 64.9L27.1 48.8l-5.7 5.7 21.8 21.8L72.9 36.2l-5.7-5.7L37.5 59.2z" />
                        </svg>

                        <h1 className="mb-2 text-3xl font-semibold text-gray-800">Paiement réussi!</h1>
                        <h4 className="font-bold text-center ">
                            <span className="text-4xl">
                                {Number( transactionDetail.montant ?? transactionDetail.amount ).toLocaleString()}  {transactionDetail.currency}
                            </span>
                        </h4>                        
                        <p className="text-lg text-gray-500 ">Votre paiement a été effectué avec succès</p>
                        <span className='mb-8 ml-1 font-bold text-center text-gray-900'>
                            { serviceName }
                        </span>
                        <button onClick={returnToSite} className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600">Retour à l'accueil</button>
                        
                        
                        <Footer transactionDetail={transactionDetail}/>
                    </div>

                </div>

          </div>
    </>
  )
}

export default Success